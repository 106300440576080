'use client'
import Link from 'next/link'
import React, { useEffect, useState } from 'react'
import { Button } from './ui/button'
import { RiFacebookCircleFill, RiFileCopyFill, RiTelegramFill,  RiTwitterXFill } from 'react-icons/ri'
import { FaWhatsapp } from 'react-icons/fa'

const ShareButtons = ({article}) => {
    const [currentUrl, setCurrentUrl] = useState('');

    
    const copyToClipboard = () => {
        navigator.clipboard.writeText(currentUrl)
        .then(() => alert('Link copied to clipboard!'))
        .catch(err => console.error('Failed to copy: ', err));
    };


    useEffect(()=>{
        if(article){
            setCurrentUrl(`https://time4.news/${article.infos.shortcut}`)
        }
    },[article])

  return (
    <>
        <Link target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(currentUrl)}`}>
            <Button  size="icon" variant="icon">
              <RiFacebookCircleFill className="text-lg md:text-xl text-primary" />
            </Button>
        </Link>
        <Link target='_blank' href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(article?.MainInfo?.title)}`}>
          <Button size="icon" variant="icon">
            <RiTwitterXFill className="text-lg md:text-xl text-primary" />
          </Button>
        </Link>
        <Link target='_blank' href={`https://t.me/share/url?url=${encodeURIComponent(currentUrl)}&text=${encodeURIComponent(article?.MainInfo?.title)}`}>
          <Button size="icon" variant="icon">
            <RiTelegramFill className="text-lg md:text-xl text-primary" />
          </Button>
        </Link>
        <Link target='_blank' href={`https://wa.me/?text=${encodeURIComponent(currentUrl)}`}>
        <Button size="icon" variant="icon">
          <FaWhatsapp className="text-lg md:text-xl text-primary" />
        </Button>
      </Link>
        <Button size="icon" variant="icon" onClick={copyToClipboard}>
            <RiFileCopyFill className="text-lg md:text-xl text-primary" />
        </Button>
                
    </>
  )
}

export default ShareButtons